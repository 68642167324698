import React, {
  useEffect,
  useState,
  useCallback,
  createContext,
  type Context,
  type ReactNode,
} from 'react'
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import localforage from 'localforage'

import { trackEvent, type GtmEvent } from '~/lib/utils/google-tag-manager'
import debug from '~/lib/utils/debug'
import { getAuthAccessToken } from '~/lib/providers/utils/auth'
import { createApolloClient } from '~/graphql/client'
import {
  type ReactionType,
  type CollectionUser,
  type CollectionItems,
  type GetCollectionsQuery,
  type GetCollectionsQueryVariables,
  GetCollectionsDocument,
  type GetCollectionsQueryResult,
  type AddCollectionsMutation,
  type AddCollectionsMutationVariables,
  AddCollectionsDocument,
  type AddCollectionsInputItem,
  type DeleteCollectionMutation,
  type DeleteCollectionMutationVariables,
  DeleteCollectionDocument,
  type UpdateCollectionMutation,
  type UpdateCollectionMutationVariables,
  UpdateCollectionDocument,
  type UpdateListingInCollectionsMutation,
  type UpdateListingInCollectionsMutationVariables,
  UpdateListingInCollectionsDocument,
  type AddCollectionInviteMutation,
  type AddCollectionInviteMutationVariables,
  AddCollectionInviteDocument,
  type AcceptCollectionInvitationMutation,
  type AcceptCollectionInvitationMutationVariables,
  AcceptCollectionInvitationDocument,
  type DeleteCollectionInviteMutation,
  type DeleteCollectionInviteMutationVariables,
  DeleteCollectionInviteDocument,
  type RemoveCollectionCollaboratorMutation,
  type RemoveCollectionCollaboratorMutationVariables,
  RemoveCollectionCollaboratorDocument,
  type SaveCollectionReactionMutation,
  type SaveCollectionReactionMutationVariables,
  SaveCollectionReactionDocument,
} from '~/graphql/generated/graphql'

import { useUser } from './user-context'
import { useAsync } from './utils/use-async'

// Pending auth caching

let pendingCollection = null

const storage = localforage.createInstance({
  name: 'Homely-collections',
  driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE],
})

const getCachedPendingCollection = () =>
  storage
    .getItem('pending')
    .then(value => {
      return value
    })
    .catch(() => {
      return pendingCollection
    })

const cachePendingCollection = (addCollectionsInputItem?: AddCollectionsInputItem) => {
  storage
    .setItem('pending', addCollectionsInputItem)
    .then(() => {})
    .catch(() => {
      pendingCollection = addCollectionsInputItem
    })
}

const getCachedPendingCollectionTracking = (): Promise<GtmEvent | null> =>
  storage.getItem('pendingTracking')
const cachePendingCollectionTracking = (tracking: GtmEvent) =>
  storage.setItem('pendingTracking', tracking)

interface CollectionsContextProps {
  collections: CollectionItems[]
  loading: boolean
  error: boolean
  ready: boolean
  addCollection: (addCollectionsInputItem: AddCollectionsInputItem) => Promise<void>
  updateCollection: (collection: CollectionItems) => Promise<void>
  deleteCollection: (collectionId: number) => Promise<void>
  inviteToCollection: (email: string, collection: CollectionItems) => Promise<void>
  confirmInvite: (collectionId: number, memberId: number, uniqueIdentifier: string) => Promise<void>
  removeInvite: (email: string, collection: CollectionItems) => Promise<void>
  removeUser: (user, collection: CollectionItems) => Promise<void>
  addReaction: (
    collectionId: number,
    listingId: number,
    reactionType: ReactionType,
    comment: string,
  ) => Promise<void>
  cachePendingCollection: (addCollectionsInputItem: AddCollectionsInputItem) => void
  cachePendingCollectionTracking: (tracking: GtmEvent) => void
  checkListingInAllCollections: (listingId: number) => boolean
  checkListingInCollection: (listingId: number, collection: CollectionItems) => boolean
  toggleListing: (listingId: number, collection: CollectionItems) => Promise<void>
}

const CollectionsContext: Context<CollectionsContextProps> = createContext(
  {} as CollectionsContextProps,
)

CollectionsContext.displayName = 'CollectionsContext'

interface Props {
  initialCollections?: any[]
  children: ReactNode
}

const CollectionsProvider = ({ initialCollections = [], ...props }: Props) => {
  const { data, setData } = useAsync({ data: initialCollections })

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [listLoading, setListLoading] = useState(false)
  const [listError, setListError] = useState(null)
  const [accessToken, setAccessToken] = useState(null)
  const [apolloClient, setApolloClient] = useState(null as ApolloClient<NormalizedCacheObject>)
  const { user } = useUser()

  const load = useCallback(async () => {
    // Need to set client here to ensure we get auth headers
    setListError(null)
    if (user && apolloClient) {
      setListLoading(true)
      apolloClient
        .query<GetCollectionsQuery, GetCollectionsQueryVariables>({
          query: GetCollectionsDocument,
          variables: {
            username: user.username,
            imageCount: 3,
          },
          fetchPolicy: 'no-cache',
          context: { headers: { ...accessToken } },
        })
        .then(response => {
          setData(response.data.collections as GetCollectionsQueryResult)
          setListLoading(false)
          setListError(null)
        })
        .catch(e => {
          setListLoading(false)
          setListError(e.message)
        })
    } else {
      // User doesn't have access token or isn't logged in
      setListLoading(false)
      setData(null)
    }
  }, [setListLoading, setListError, setData, user, accessToken, apolloClient])

  const addCollection = (addCollectionsInputItem: AddCollectionsInputItem) => {
    setError(null)
    setLoading(true)
    return apolloClient
      .mutate<AddCollectionsMutation, AddCollectionsMutationVariables>({
        mutation: AddCollectionsDocument,
        variables: {
          username: user.username,
          addCollectionsInput: {
            items: [addCollectionsInputItem],
          },
        },
        context: { headers: { ...accessToken } },
      })
      .then(res => {
        if (res?.data?.addCollections?.items?.[0]?.id) {
          load()
        } else {
          setError('Failed to delete Collection')
        }
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        setError(e.message)
      })
  }

  const updateCollection = (collection: CollectionItems) => {
    setError(null)
    setLoading(true)
    return apolloClient
      .mutate<UpdateCollectionMutation, UpdateCollectionMutationVariables>({
        mutation: UpdateCollectionDocument,
        variables: {
          collectionId: collection.id,
          editCollectionInput: {
            isPublic: collection.isPublic,
            name: collection.name,
          },
        },
        context: { headers: { ...accessToken } },
      })
      .then(res => {
        if (res?.data?.updateCollection) {
          load()
        } else {
          setError('Failed to update Collection')
        }
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        setError(e.message)
      })
  }

  const deleteCollection = (collectionId: number) => {
    setError(null)
    setLoading(true)
    return apolloClient
      .mutate<DeleteCollectionMutation, DeleteCollectionMutationVariables>({
        mutation: DeleteCollectionDocument,
        variables: { collectionId },
        context: { headers: { ...accessToken } },
      })
      .then(res => {
        if (res?.data?.deleteCollection?.collectionId) {
          load()
        } else {
          setError('Failed to delete Collection')
        }
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        setError(e.message)
      })
  }

  const inviteToCollection = (email: string, collection: CollectionItems) => {
    setError(null)
    setLoading(true)
    return apolloClient
      .mutate<AddCollectionInviteMutation, AddCollectionInviteMutationVariables>({
        mutation: AddCollectionInviteDocument,
        variables: {
          collectionId: collection.id,
          collectionInviteInput: {
            emails: [email],
          },
        },
        context: { headers: { ...accessToken } },
      })
      .then(res => {
        if (res?.data?.addCollectionInvite) {
          load()
        } else {
          setError('Failed to invite to Collection')
        }
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        setError(e.message)
      })
  }

  const confirmInvite = (collectionId: number, memberId: number, uniqueIdentifier: string) => {
    setError(null)
    setLoading(true)
    return apolloClient
      .mutate<AcceptCollectionInvitationMutation, AcceptCollectionInvitationMutationVariables>({
        mutation: AcceptCollectionInvitationDocument,
        variables: {
          collectionId,
          confirmInviteInput: {
            memberId,
            uniqueIdentifier,
          },
        },
        context: { headers: { ...accessToken } },
      })
      .then(res => {
        if (res?.data?.acceptCollectionInvitation) {
          load()
        } else {
          setError('Failed to confirm invite')
        }
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        setError(e.message)
      })
  }

  const removeInvite = (email: string, collection: CollectionItems) => {
    setError(null)
    setLoading(true)
    return apolloClient
      .mutate<DeleteCollectionInviteMutation, DeleteCollectionInviteMutationVariables>({
        mutation: DeleteCollectionInviteDocument,
        variables: {
          collectionId: collection.id,
          collectionInviteInput: {
            emails: [email],
          },
        },
        context: { headers: { ...accessToken } },
      })
      .then(res => {
        if (res?.data?.deleteCollectionInvite) {
          load()
        } else {
          setError('Failed to remove invite')
        }
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        setError(e.message)
      })
  }

  const removeUser = (usr: CollectionUser, collection: CollectionItems) => {
    setError(null)
    setLoading(true)
    return apolloClient
      .mutate<RemoveCollectionCollaboratorMutation, RemoveCollectionCollaboratorMutationVariables>({
        mutation: RemoveCollectionCollaboratorDocument,
        variables: {
          collectionId: collection.id,
          memberId: usr.memberId,
        },
        context: { headers: { ...accessToken } },
      })
      .then(res => {
        if (res?.data?.removeCollectionCollaborator) {
          load()
        } else {
          setError('Failed to remove collaborator')
        }
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        setError(e.message)
      })
  }

  const addReaction = (
    collectionId: number,
    listingId: number,
    reactionType: ReactionType,
    comment: string,
  ) => {
    setError(null)
    setLoading(true)
    return apolloClient
      .mutate<SaveCollectionReactionMutation, SaveCollectionReactionMutationVariables>({
        mutation: SaveCollectionReactionDocument,
        variables: {
          collectionId,
          listingId,
          saveCollectionListingReactionInput: {
            comment,
            reactionType,
          },
        },
        context: { headers: { ...accessToken } },
      })
      .then(res => {
        if (res?.data?.saveCollectionReaction) {
          load()
        } else {
          setError('Failed to add reaction')
        }
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        setError(e.message)
      })
  }

  const checkListingInAllCollections = listingId =>
    data?.items && listingId
      ? !!data.items.find(collection =>
          collection.listings.find(listing => listing.listingId === listingId),
        )
      : false

  const checkListingInCollection = (listingId, collection) =>
    listingId && collection
      ? !!collection.listings.find(listing => listing.listingId === listingId)
      : false

  const toggleListing = (listingId: number, collection: CollectionItems) => {
    const exists = checkListingInCollection(listingId, collection)
    setError(null)
    setLoading(true)
    return apolloClient
      .mutate<UpdateListingInCollectionsMutation, UpdateListingInCollectionsMutationVariables>({
        mutation: UpdateListingInCollectionsDocument,
        variables: {
          username: user.username,
          listingId,
          updateListingInCollectionsInput: {
            addToCollectionIds: exists ? [] : [collection.id],
            removeFromCollectionIds: !exists ? [] : [collection.id],
          },
        },
        context: { headers: { ...accessToken } },
      })
      .then(res => {
        if (res?.data?.updateListingInCollections) {
          load()
        } else {
          setError('Failed to update Collection')
        }
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        setError(e.message)
      })
  }

  const checkPendingCollections = () =>
    getCachedPendingCollection().then((cached: any) => {
      if (cached) {
        return addCollection(cached)
          .then(() => {
            cachePendingCollection(null)
            getCachedPendingCollectionTracking().then(tracking => {
              if (tracking) {
                cachePendingCollectionTracking(null)
                trackEvent(tracking)
              }
            })
          })
          .catch(err => {
            if (err.stack) {
              debug.error(err.stack)
            }
          })
      }
      return Promise.resolve()
    })

  useEffect(() => {
    if (user && apolloClient) {
      // User logged in.
      checkPendingCollections().then(() => load()) // TODO
    } else {
      setData(null)
    }
  }, [user, apolloClient])

  useEffect(() => {
    const authAccessToken = getAuthAccessToken()
    setAccessToken(authAccessToken)

    // As we are setting unique headers for these requests we don't want to use the main Apollo Client
    const client: ApolloClient<NormalizedCacheObject> = authAccessToken
      ? createApolloClient()
      : null
    setApolloClient(client)
  }, [user, setAccessToken, setApolloClient])

  return (
    <CollectionsContext.Provider
      value={{
        collections: data?.items,
        loading: listLoading,
        ready: !!apolloClient,
        error: listError,
        addCollection,
        updateCollection,
        deleteCollection,
        inviteToCollection,
        confirmInvite,
        removeInvite,
        removeUser,
        addReaction,
        cachePendingCollection,
        cachePendingCollectionTracking,
        checkListingInAllCollections,
        checkListingInCollection,
        toggleListing,
      }}
      {...props}
    />
  )
}

const useCollections = () => React.useContext(CollectionsContext)

export { CollectionsContext, CollectionsProvider, useCollections }
