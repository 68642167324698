export const icons = [
  '<svg style="visibility:hidden; width: 0; height: 0; position: absolute;">',
  '<defs>',
  '<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a"><stop stop-color="#FD6D90" offset="0%"></stop><stop stop-color="#FF3FDD" offset="100%"></stop></linearGradient>',
  '<linearGradient x1="100%" y1="50%" x2="0%" y2="50%" id="b"><stop stop-color="#F07293" offset="0%"/><stop stop-color="#EB2CD4" offset="50%"/><stop stop-color="#863CE0" offset="100%"/></linearGradient>',
  '<circle id="c" cx="16" cy="16" r="16"/>',
  '</defs>',
  '</svg><svg xmlns="http://www.w3.org/2000/svg" style="display:none">',
  '<symbol id="icon-logo" viewBox="0 0 142 33"><g fill="none" fill-rule="evenodd"><path d="M36.528 27V5.688h4.78v6.797h.058c.807-1.44 2.42-2.016 4.119-2.016 1.526 0 3.34.547 4.349 1.757 1.353 1.584 1.497 2.995 1.497 5.846V27h-4.78v-8.698c0-.777 0-3.34-2.564-3.34-2.678 0-2.678 2.39-2.678 3.168V27h-4.781zm17.112-8.006c0-3.917 2.793-8.496 8.61-8.496 5.818 0 8.612 4.579 8.612 8.496 0 3.916-2.794 8.496-8.611 8.496-5.818 0-8.611-4.58-8.611-8.496zm4.78 0c0 2.332 1.757 4.032 3.83 4.032 2.074 0 3.831-1.7 3.831-4.032 0-2.333-1.757-4.032-3.83-4.032-2.074 0-3.83 1.699-3.83 4.032zM73.228 27V11.016h4.464v1.7h.058c.374-.663 1.41-2.247 4.406-2.247 1.411 0 3.34.49 4.608 2.851.98-1.555 2.62-2.851 5.069-2.851 1.123 0 2.909.259 4.205 1.613 1.497 1.584 1.728 2.65 1.728 6.192V27h-4.781v-8.496c0-1.296 0-3.542-2.448-3.542-2.65 0-2.65 2.419-2.65 3.312V27h-4.78v-8.698c0-.864 0-3.34-2.391-3.34-2.707 0-2.707 2.592-2.707 3.542V27h-4.781zm38.078-5.011h4.896c-.778 1.785-1.9 3.168-3.254 4.09a7.768 7.768 0 0 1-4.55 1.44c-4.58 0-8.468-3.716-8.468-8.468 0-4.464 3.514-8.582 8.38-8.582 4.868 0 8.44 3.859 8.44 8.726 0 .634-.058.893-.116 1.267h-11.98c.287 1.901 1.871 3.024 3.743 3.024 1.469 0 2.247-.662 2.909-1.497zm-6.595-4.752h7.257c-.201-.95-1.152-2.736-3.628-2.736-2.477 0-3.428 1.785-3.63 2.736zM119.058 27V5.688h4.78V27h-4.78zm17.6-15.984h5.07l-8.007 21.312h-5.069l2.535-6.134-5.933-15.178h5.098l3.196 9.274h.058l3.053-9.274z" fill="#101010"/><path d="M0 15.22c0-1.509.648-2.948 1.785-3.963l11.38-10.16a2.764 2.764 0 0 1 3.67 0l11.38 10.16A5.314 5.314 0 0 1 30 15.22v13.7c0 1.48-1.221 2.679-2.727 2.679H2.727C1.221 31.6 0 30.4 0 28.922V15.22z" fill="#ff0086" fill-rule="nonzero"/><path d="M6.3 19c2.5 2.4 5.4 3.6 8.7 3.6 3.3 0 6.2-1.2 8.7-3.6" stroke="#FFF" stroke-width="3.6"/></g></symbol>',
  '<symbol id="icon-profile" viewBox="0 0 32 32"><g fill="none" fill-rule="evenodd"><path d="M0 0h32v32H0z"/><path d="M15.889 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM24 24.6c0-3.535-3.581-6.4-8-6.4-4.419 0-8 2.865-8 6.4m0 0h16" stroke="currentColor" stroke-linecap="round" stroke-width="1.32"/></g></symbol>',
  '<symbol id="icon-add" viewBox="0 0 32 32"><path fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M16 9v14M9 16h14"/></symbol>',
  '<symbol id="icon-subtract" viewBox="0 0 32 32"><path fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M9 16h14"/></symbol>',
  '<symbol id="icon-heart" viewBox="0 0 32 32"><path d="M15.562 26.843h0c.119.1.274.157.438.157a.68.68 0 00.438-.156c.945-.797 1.72-1.438 3.2-2.652 3.988-3.274 5.453-4.568 6.827-6.165C28.212 15.996 29 14.132 29 12.107c0-1.964-.693-3.76-1.944-5.067C25.797 5.724 24.07 5 22.178 5c-1.4 0-2.671.423-3.802 1.265-.367.273-.915.825-1.619 1.64L16 8.784l-.757-.877c-.704-.816-1.252-1.368-1.62-1.641A6.216 6.216 0 009.823 5c-1.892 0-3.62.724-4.878 2.04C3.693 8.347 3 10.144 3 12.107c0 2.025.788 3.889 2.535 5.92 1.374 1.597 2.84 2.892 6.836 6.172l-.003-.002a299.567 299.567 0 013.194 2.646z" stroke="inherit" stroke-width="2" fill="none" fill-rule="evenodd"/></symbol>',
  '<symbol id="icon-heart-full" viewBox="0 0 32 32"><path d="M25.779 2.349C24.329.834 22.34 0 20.179 0a7.216 7.216 0 00-4.4 1.463A8.824 8.824 0 0014 3.253a8.819 8.819 0 00-1.779-1.79A7.215 7.215 0 007.821 0c-2.161 0-4.15.834-5.6 2.349C.789 3.846 0 5.89 0 8.107c0 2.282.882 4.37 2.777 6.572 1.695 1.97 4.13 3.97 6.952 6.287.963.79 2.055 1.687 3.189 2.642.3.253.683.392 1.082.392.398 0 .783-.14 1.082-.391 1.134-.956 2.226-1.853 3.19-2.644 2.82-2.315 5.256-4.315 6.951-6.286C27.118 12.477 28 10.39 28 8.107c0-2.216-.789-4.261-2.221-5.758z" transform="translate(2 4)" fill="url(#a)" fill-rule="evenodd"/></symbol>',
  '<symbol id="icon-draw-map-desktop" viewBox="0 0 28 28"><g fill="none" fill-rule="evenodd"><path fill="#FFF" d="M0 0h28v28H0z"/><path d="M19.003 21.583c-4.742-1.832-7.828 1.197-8.75 1.657-1.179.588-2.083 1.249-3.225.083-.47-.48-1.243-1.236-.972.18.201 1.053 1.63 2.013 4.387.678 2.004-.97 3.365-2.979 7.584-1.938 1.386.341 2.507 1.175 2.987 2.443.209.553 1.167.315.956-.243-.512-1.352-1.546-2.311-2.967-2.86z" stroke="currentColor" stroke-width=".5" fill="#343D42" fill-rule="nonzero"/><path d="M20.933 2.725a2.479 2.479 0 00-3.502 0l-.877.881-9.333 9.33-.02.02c-.005.004-.005.009-.01.009-.01.015-.025.03-.034.044 0 .005-.006.005-.006.01l-.024.04c-.005.005-.005.01-.01.015l-.015.04c0 .004-.005.004-.005.01l-2.07 6.227a.488.488 0 00.118.505.498.498 0 00.51.12l6.223-2.077c.004 0 .004 0 .01-.004a.18.18 0 00.044-.02.018.018 0 00.01-.005l.05-.03.044-.035c.005-.005.01-.005.01-.01.005-.005.015-.01.02-.02l10.21-10.21a2.479 2.479 0 000-3.503l-1.343-1.337zm-9.214 14.01l-3.448-3.448 8.63-8.63 3.447 3.448-8.629 8.63zm-3.933-2.531l3.012 3.012-4.523 1.506 1.51-4.518zM21.577 6.87l-.525.53-3.448-3.448.53-.53c.58-.58 1.52-.58 2.1 0l1.348 1.348c.576.582.573 1.52-.005 2.1z" fill="#343D42" fill-rule="nonzero" stroke="currentColor" stroke-width=".589"/></g></symbol>',
  '<symbol id="icon-draw-map-mobile" viewBox="0 0 28 28"><g fill="none" fill-rule="evenodd"><path fill="#FFF" d="M0 0h28v28H0z"/><path d="M18.566 11.959h-.03c-.417-.003-.82.15-1.13.43-.251-.696-.89-1.195-1.637-1.195a1.698 1.698 0 00-1.213.522c-.291-.591-.877-.992-1.55-.992h-.032a1.592 1.592 0 00-.998.333V6.846C11.976 5.828 11.183 5 10.22 5s-1.751.827-1.752 1.844L8.46 15.2l-.473-.575a2.385 2.385 0 00-1.685-.885 2.347 2.347 0 00-1.758.622l-.412.341a.366.366 0 00-.09.45l4 7.69c.73 1.405 2.123 2.281 3.631 2.281h4.508v-.003c2.286 0 4.14-1.984 4.142-4.42 0-1.089-.004-1.901-.003-2.56.002-1.76 0-2.41-.004-4.35-.002-1.016-.789-1.832-1.75-1.832zm1.017 6.18l-.003 2.564c-.002 2.032-1.536 3.69-3.42 3.69h-4.487c-1.235 0-2.378-.726-2.982-1.888L4.826 15.08l.191-.16.014-.012c.328-.309.77-.467 1.22-.438.459.035.883.26 1.17.62l1.12 1.362a.365.365 0 00.648-.232l.006-9.375c0-.614.465-1.114 1.025-1.114.56 0 1.024.5 1.024 1.115V15a.366.366 0 00.732 0v-2.49c0-.592.452-1.055.998-1.055h.032c.56 0 1.027.488 1.027 1.102v2.212a.366.366 0 00.731 0v-1.735c0-.615.444-1.114 1.007-1.114.56 0 1.005.5 1.005 1.114v1.637a.366.366 0 00.731 0v-.887c0-.614.468-1.095 1.029-1.095h.03c.559 0 1.015.49 1.016 1.102.004 1.938.004 2.587.001 4.347z" fill="#343D42" fill-rule="nonzero" stroke="currentColor" stroke-width=".667"/><path d="M15.248 3.437c3.556-1.374 5.871.898 6.562 1.243.884.441 1.563.937 2.419.062.353-.36.932-.927.729.135-.151.79-1.223 1.51-3.29.509-1.503-.728-2.524-2.234-5.688-1.454-1.04.256-1.88.881-2.24 1.832-.157.415-.876.237-.717-.182.383-1.014 1.16-1.733 2.225-2.145z" stroke="currentColor" stroke-width=".824" fill="#343D42" fill-rule="nonzero"/></g></symbol>',
  '<symbol id="icon-create-alert" viewBox="0 0 24 24"><g fill-rule="nonzero" fill="inherit"><path d="M8.026 19.933v.092c0 1.862 1.558 3.375 3.444 3.375s3.443-1.513 3.443-3.375v-.092a.864.864 0 0 0-.86-.866.864.864 0 0 0-.862.866v.092c0 .89-.773 1.642-1.721 1.642-.95 0-1.722-.751-1.722-1.642v-.092a.864.864 0 0 0-.861-.866.864.864 0 0 0-.86.866Z" fill="currentColor"/><path d="M18.637 12.368V9.76c0-3.85-3.319-7.161-7.167-7.161-3.85 0-7.168 3.345-7.168 7.224v2.544c0 .997-.28 1.614-.944 2.392-.034.04-.468.533-.593.686-.505.621-.765 1.175-.765 1.88C2 19.59 5.844 20.8 11.47 20.8c5.625 0 9.47-1.21 9.47-3.473 0-.706-.26-1.26-.766-1.88-.125-.154-.56-.647-.593-.687-.663-.778-.944-1.395-.944-2.392Zm-1.722 0c0 1.466.45 2.454 1.359 3.52.053.062.469.535.568.657.277.34.375.55.375.782 0 .763-3.103 1.74-7.747 1.74-4.645 0-7.748-.977-7.748-1.74 0-.232.098-.442.375-.782.1-.122.515-.595.568-.657.908-1.066 1.359-2.054 1.359-3.52V9.824c0-2.923 2.547-5.49 5.446-5.49 2.9 0 5.445 2.538 5.445 5.427v2.607Z" fill="currentColor"/><path d="M16.915 12.368c0 1.466.45 2.454 1.359 3.52.053.062.469.535.568.657.277.34.375.55.375.782 0 .763-3.103 1.74-7.747 1.74-4.645 0-7.748-.977-7.748-1.74 0-.232.098-.442.375-.782.1-.122.515-.595.568-.657.908-1.066 1.359-2.054 1.359-3.52V9.824c0-2.923 2.547-5.49 5.446-5.49 2.9 0 5.445 2.538 5.445 5.427v2.607Z" fill="inherit"/><path d="M17.202 1.396a6.254 6.254 0 0 1 2.15 2 6.14 6.14 0 0 1 .988 2.736.604.604 0 0 0 .664.538.606.606 0 0 0 .534-.668 7.358 7.358 0 0 0-1.183-3.279A7.462 7.462 0 0 0 17.79.337a.6.6 0 0 0-.82.234.609.609 0 0 0 .232.825Z" fill="currentColor"/><path d="M16.303 3.107c1.142.757 1.967 1.865 2.336 3.13a.602.602 0 1 0 1.157-.341c-.45-1.544-1.452-2.888-2.83-3.803a.6.6 0 0 0-.834.173.61.61 0 0 0 .171.84Z" fill="currentColor"/></g></symbol>',
  '<symbol id="icon-drop-down" viewBox="0 0 32 32"><path fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l4 4 4-4"/></symbol>',
  '<symbol id="icon-search-small" viewBox="0 0 32 32"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-width="2"><circle cx="15" cy="15" r="5"/><path stroke-linecap="round" d="M18.82 18.82l3.536 3.536"/></g></symbol>',
  '<symbol id="icon-photo" viewBox="0 0 24 24"><g stroke="currentColor" stroke-width="1.2" fill="none" fill-rule="evenodd"><rect x="3" y="5" width="18" height="14" rx="1.636"/><circle cx="7.523" cy="9.75" r="1.25"/><path stroke-linecap="round" stroke-linejoin="round" d="M3.818 17.688L7.5 13.313 9.955 15.5l4.5-4.813L21 16.375"/></g></symbol>',
  '<symbol id="icon-floorplan" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-width="1.4"><path d="M20 16.833a2.667 2.667 0 0 1-2.667 2.667H6.667A2.667 2.667 0 0 1 4 16.833V6.167A2.667 2.667 0 0 1 6.667 3.5h10.666A2.667 2.667 0 0 1 20 6.167v6.222M10.75 12.335H4M10.855 9V3.29M10.855 16.5v-4.865"/></g></symbol>',  
  '<symbol id="icon-interactive-floorplan" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><g stroke="currentColor" stroke-width="1.4"><path d="M16.908 19.5H6.667A2.667 2.667 0 0 1 4 16.833V6.167A2.667 2.667 0 0 1 6.667 3.5h10.666A2.667 2.667 0 0 1 20 6.167v6.222M10.75 12.335H4M10.855 9V3.29M10.855 16.5v-4.865"/></g><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4" d="m15.246 15.139 2.09 6.27c.263.788 1.377.788 1.64 0l.771-2.315a.864.864 0 0 1 .547-.547l2.315-.772c.788-.262.788-1.376 0-1.64l-6.27-2.09a.864.864 0 0 0-1.093 1.094Z"/></g></symbol>',
  '<symbol id="icon-play" viewBox="0 0 32 32"><g fill="none" fill-rule="evenodd"><path d="M0 0h32v32H0z"/><path stroke="currentColor" stroke-width="2" d="M24.475 16L9 7.352v17.296L24.475 16z"/></g></symbol>',
  '<symbol id="icon-share" viewBox="0 0 32 32"><g fill="currentColor" fill-rule="nonzero"><path d="M10.331 6.757a1 1 0 101.338 1.486l-1.338-1.486zM16 3l.669-.743a.999.999 0 00-1.338 0L16 3zm4.331 5.243a1 1 0 101.338-1.486l-1.338 1.486zM15 21a1 1 0 002 0h-2zM11.669 8.243l5-4.5-1.338-1.486-5 4.5 1.338 1.486zm3.662-4.5l5 4.5 1.338-1.486-5-4.5-1.338 1.486zM15 3v18h2V3h-2zM11 13a1 1 0 000-2v2zm10-2a1 1 0 000 2v-2zm4 3v13h2V14h-2zm-1 14H8v2h16v-2zM7 27V14H5v13h2zm1-14h3v-2H8v2zm13 0h3v-2h-3v2zM8 28a1 1 0 01-1-1H5a3 3 0 003 3v-2zm17-1a1 1 0 01-1 1v2a3 3 0 003-3h-2zm2-13a3 3 0 00-3-3v2a1 1 0 011 1h2zM7 14a1 1 0 011-1v-2a3 3 0 00-3 3h2z"/></g></symbol>',
  '<symbol id="icon-next" viewBox="0 0 32 32"><path d="M10.276 5.69l9.66 10.136a.267.267 0 010 .348l-9.66 10.136a1 1 0 001.448 1.38l9.66-10.136a2.266 2.266 0 000-3.108L11.723 4.31a1 1 0 00-1.448 1.38z" fill="currentColor" fill-rule="nonzero"/></symbol>',
  '<symbol id="icon-fullscreen" viewBox="0 0 32 32"><path fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M27 13V5h-8M27 19v8h-8M13 27H5v-8M13 5H5v8"/></symbol>',
  '<symbol id="icon-close-large" viewBox="0 0 32 32"><g stroke="currentColor" stroke-width="3" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M2.368 1.593L18.446 17.67M18.407 1.5L2.33 17.577"/></g></symbol>',
  '<symbol id="icon-back-large" viewBox="0 0 32 32"><path fill="currentColor" fill-rule="nonzero" d="M10.308 16c0 .256.097.512.293.707l9.193 9.192c.39.39 1.024.39 1.414 0 .39-.39.39-1.024 0-1.415L12.722 16l8.485-8.485c.39-.39.39-1.024 0-1.414-.39-.39-1.024-.39-1.414 0L10.6 15.294c-.195.195-.292.45-.292.707z"/></symbol>',
  '<symbol id="icon-close" viewBox="0 0 512 512"><path d="m279 256l68 68c6 6 6 16 0 23-7 6-17 6-23 0l-68-68-68 68c-6 6-16 6-23 0-6-7-6-17 0-23l68-68-68-68c-6-6-6-16 0-23 7-6 17-6 23 0l68 68 68-68c6-6 16-6 23 0 6 7 6 17 0 23z"/></symbol>',
  '<symbol id="icon-fullscreen-close" viewBox="0 0 512 512"><path d="m208 216c4 0 8-4 8-8l0-128c0-4-4-8-8-8-4 0-8 4-8 8l0 120-120 0c-4 0-8 4-8 8 0 4 4 8 8 8z m-8 96l0 120c0 4 4 8 8 8 4 0 8-4 8-8l0-128c0-4-4-8-8-8l-128 0c-4 0-8 4-8 8 0 4 4 8 8 8z m112-112l0-120c0-4-4-8-8-8-4 0-8 4-8 8l0 128c0 4 4 8 8 8l128 0c4 0 8-4 8-8 0-4-4-8-8-8z m-8 96c-4 0-8 4-8 8l0 128c0 4 4 8 8 8 4 0 8-4 8-8l0-120 120 0c4 0 8-4 8-8 0-4-4-8-8-8z"/></symbol>',
  '<symbol id="icon-collapse" viewBox="0 0 512 512"><path d="m256 305l136-135c6-7 16-7 22 0 7 6 7 16 0 22l-147 147c-3 3-7 5-11 5-4 0-8-2-11-5l-147-147c-7-6-7-16 0-22 6-7 16-7 22 0z"/></symbol>',
  '<symbol id="icon-tick" viewBox="0 0 512 512"><path d="m222 315l149-149c6-6 16-6 23 0 6 7 6 17 0 23l-159 158c0 1 0 1-1 1 0 0 0 1 0 1-7 6-17 6-23 0l-68-68c-6-6-6-16 0-23 6-6 17-6 23 0z"/></symbol>',
  '<symbol id="icon-trash" viewBox="0 0 512 512"><path d="m144 189l43 224c0 1 2 3 3 3l116 0c1 0 3-2 3-3l43-224c0 1 1 3 3 3l-214 0c1 0 3-2 3-3z m-16 3c-1-9 4-16 13-16l214 0c9 0 14 7 13 16l-43 224c-2 9-10 16-19 16l-116 0c-8 0-17-7-19-16z m154-106l-48-6c7 1 12-3 15-8 0-1 1-2 1-2 0 1 0 3 0 8 0 0 0 0 0 1 0 6 0 8 0 11 0 3-1 6-2 8-3 7-9 12-18 11-10-1-10-1-21-3-34-5-34-5-46-6-2-1-5 1-6 3l-8 17c1-2-1-6-3-7l209 30c-2 0-5 3-5 5l-3-18c0-2-3-5-4-5l-65-9c-9-1-14-8-15-15 0-3 0-6 0-9 1-3 2-6 4-12 0 0 0 0 0 0 1-5 2-6 2-8 0 0 0 1 0 2 1 6 5 11 13 12 0 0 0 0 0 0z m0-2l0 0c0 0 0 0 0 0-2 8-5 16-2 16l65 9c9 1 17 9 18 18l3 18c2 9-4 15-13 14l-209-30c-9-1-13-8-9-16l8-17c3-8 13-13 22-12 0 0 39 5 67 9 3 1 2-8 2-16 0 0 0 0 0 0 0 0 0 0 0 0-1-7-1-14 2-13l48 7c3 0 1 6-2 13z m-42 148l0 144c0 4 4 8 8 8 4 0 8-4 8-8l0-144c0-4-4-8-8-8-4 0-8 4-8 8z m-52 2l20 143c1 4 5 8 9 7 5-1 8-5 7-9l-20-143c-1-4-5-7-9-7-4 1-8 5-7 9z m104-2l-20 143c-1 4 2 8 7 9 4 1 8-3 9-7l20-142c1-5-2-9-7-10-4 0-8 3-9 7z"/></symbol>',
  '<symbol id="icon-search" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><g transform="translate(4 4)" stroke="currentColor" stroke-width="2"><circle cx="6.353" cy="6.353" r="6.353"/><path stroke-linecap="round" d="M11.092 11.294l4.184 3.993"/></g></g></symbol>',
  '<symbol id="icon-office" viewBox="0 0 512 512"><path d="m352 281l0 150c62-33 104-99 104-175 0-110-90-200-200-200-110 0-200 90-200 200 0 76 42 142 104 175l0-148c-4 0-7 0-9 0-2 0-4 0-7-1-5-3-10-8-15-14-2-3-4-6-5-9-2-2-2-4-2-4-7-11 14-36 56-76 36-33 67-58 78-59 21-1 142 105 141 123-2 18-21 38-38 38-2 0-4 0-7 0z m-312-25c0-119 97-216 216-216 119 0 216 97 216 216 0 119-97 216-216 216-119 0-216-97-216-216z m217-120c-5 0-37 26-68 55-16 15-30 30-40 41-5 6-9 11-11 14-1 2-2 3-2 4 0-1 3 4 6 8 4 5 7 8 9 9 8 0 27 0 54-2 4 0 4 0 9 0 11-1 22-1 34-2 6 0 11 0 15 0 2 0 7 0 12 0 10 0 19 1 29 1 4 0 4 0 9 0 22 1 39 1 46 1 8 0 21-13 22-23 0-2-27-30-57-56-32-28-63-50-67-50z m-81 147l0 156c0 0 40 17 80 17 40 0 80-17 80-17l0-156z m116 139c2-5 3-19 3-43 0-8-1-17-1-26 0-6 0-11 0-14l0-5-4-2-38-21-32 22-3 2 0 4c0 3 0 8-1 15 0 8 0 17 0 25 0 27 0 39 4 43 8 10 65 12 72 0z m-60-43c0-8 0-16 0-25 1-6 0-10 1-10 8-6 18-15 20-14 10 5 25 12 25 14 0 2 0 6 0 9 0 9 1 18 1 26 0 19-1 34-1 34-1 0-16 2-22 1-7 0-24-3-24-2 1 1 0-23 0-33z"/></symbol>',
  '<symbol id="icon-menu" viewBox="0 0 32 32"><rect width="32" height="4" rx="2"/><rect y="7" width="32" height="4" rx="2"/><rect y="14" width="32" height="4" rx="2"/></symbol>',
  '<symbol id="icon-forward-popup" viewBox="0 0 512 512"><path d="m237 369l102-102c7-6 7-16 0-22-6-7-16-7-22 0l-102 102c-6 6-6 16 0 22 6 6 16 6 22 0z m-22-204l102 102c6 7 16 7 22 0 7-6 7-16 0-22l-102-102c-6-6-16-6-22 0-6 6-6 16 0 22z"/></symbol>',
  '<symbol id="icon-back-popup" viewBox="0 0 512 512"><path d="m275 369l-102-102c-7-6-7-16 0-22 6-7 16-7 22 0l102 102c6 6 6 16 0 22-6 6-16 6-22 0z m22-204l-102 102c-6 7-16 7-22 0-7-6-7-16 0-22l102-102c6-6 16-6 22 0 6 6 6 16 0 22z"/></symbol>',
  '<symbol id="icon-settings" viewBox="0 0 512 512"><path d="m245 400l22 0 8-32c2-5 6-9 11-11 7-2 13-5 20-9 5-2 11-2 16 1l28 17 16-16-17-28c-3-5-3-11-1-16 4-7 7-13 9-20 1-5 6-9 11-11l32-8 0-22-32-8c-5-2-10-6-11-11-2-7-5-13-9-20-2-5-2-11 1-16l17-28-16-16-28 17c-5 3-11 3-16 1-7-4-13-7-20-9-5-1-9-6-11-11l-8-32-22 0-8 32c-2 5-6 10-11 11-7 2-13 5-20 9-5 2-11 2-16-1l-28-17-16 16 17 28c3 5 3 11 1 16-4 7-7 13-9 20-2 5-6 9-11 11l-32 8 0 22 32 8c5 2 10 6 11 11 2 7 5 13 9 20 2 5 2 11-1 16l-17 28 17 16 27-17c5-3 11-3 16 0 7 3 13 6 20 8 5 2 9 6 11 11z m-14 12l-10-40c-7-2-15-5-22-9l-35 21c-3 1-6 1-7-1l-28-28c-2-1-2-4-1-7l21-35c-3-7-7-14-9-22l-40-10c-2-1-4-3-4-6l0-38c0-3 2-5 4-6l40-10c2-7 5-15 9-22l-21-35c-1-3-1-6 1-7l27-28c2-2 5-2 7-1l36 21c7-3 14-7 22-9l10-40c1-2 3-4 6-4l38 0c3 0 5 2 6 4l10 40c7 2 15 6 22 9l35-21c3-1 6-1 7 1l28 27c2 2 2 5 1 7l-21 36c3 7 7 14 9 22l40 10c2 1 4 3 4 6l0 38c0 3-2 5-4 6l-40 10c-2 8-6 15-9 22l21 35c1 3 1 6-1 7l-27 28c-2 2-5 2-7 1l-36-21c-7 3-15 7-22 9l-10 40c-1 2-3 4-6 4l-38 0c-3 0-5-2-6-4z m25-128c16 0 28-12 28-28 0-16-12-28-28-28-16 0-28 12-28 28 0 16 12 28 28 28z m0 16c-24 0-44-20-44-44 0-24 20-44 44-44 24 0 44 20 44 44 0 24-20 44-44 44z"/></symbol>',
  '<symbol id="icon-map" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><g transform="translate(5 3)" stroke="currentColor" stroke-width="1.6"><path d="M13.5 6.75C13.5 10.478 8.625 18 6.75 18S0 10.478 0 6.75a6.75 6.75 0 1113.5 0z"/><circle cx="6.75" cy="6" r="2.25"/></g></g></symbol>',
  '<symbol id="icon-star-full-large" viewBox="0 0 512 512"><path d="m256 384l-117 62c-7 4-13 0-11-9l22-129-94-92c-6-7-4-13 4-14l131-19 58-118c4-8 10-8 14 0l58 118 131 19c8 1 10 7 4 14l-94 92 22 129c2 9-4 13-11 9z"/></symbol>',
  '<symbol id="icon-star-full-medium" viewBox="0 0 512 512"><path d="m256 366l-91 48c-16 9-26 1-23-16l17-102-74-72c-13-13-9-25 9-27l102-15 46-93c8-16 20-16 28 0l46 93 102 15c18 2 22 14 9 27l-74 72 17 102c3 17-7 25-23 16z"/></symbol>',
  '<symbol id="icon-star-outline-small" viewBox="0 0 512 512"><path d="m256 308l55 29-10-61 44-43-61-9-28-57-28 57-61 8 44 44-10 61z m0 18l-48 25c-16 9-26 1-23-16l9-54-38-37c-13-13-9-25 8-27l54-8 24-49c8-15 20-16 28 0l24 49 54 8c17 2 21 14 8 27l-38 37 9 54c3 17-7 25-23 16z"/></symbol>',
  '<symbol id="icon-star-outline-medium" viewBox="0 0 512 512"><path d="m256 348l99 52-19-110 80-78-111-15-49-101-49 101-111 15 80 78-19 110z m0 18l-91 48c-16 9-26 1-23-16l17-102-74-72c-13-13-9-25 9-27l102-15 46-93c8-16 20-16 28 0l46 93 102 15c18 2 22 14 9 27l-74 72 17 102c3 17-7 25-23 16z"/></symbol>',
  '<symbol id="icon-star-outline-large" viewBox="0 0 512 512"><path d="m256 375l120 64-23-134 98-95-135-19-60-122-60 122-135 19 98 95-23 134z m0 9l-117 62c-7 4-13 0-11-9l22-129-94-92c-6-7-4-13 4-14l131-19 58-118c4-8 10-8 14 0l58 118 131 19c8 1 10 7 4 14l-94 92 22 129c2 9-4 13-11 9z"/></symbol>',
  '<symbol id="icon-star-full-small" viewBox="0 0 512 512"><path d="m256 326l-48 25c-16 9-26 1-23-16l9-54-38-37c-13-13-9-25 8-27l54-8 24-49c8-15 20-16 28 0l24 49 54 8c17 2 21 14 8 27l-38 37 9 54c3 17-7 25-23 16z"/></symbol>',
  '</svg><svg xmlns="http://www.w3.org/2000/svg" style="display:none">',
  '<symbol id="icon-google" viewBox="0 0 512 512"><path d="m258 240l0 38 64 0c-3 17-19 49-64 49-38 0-69-32-69-71 0-39 31-71 69-71 22 0 37 10 45 18l30-30c-19-18-45-29-75-29-62 0-112 50-112 112 0 62 50 112 112 112 65 0 108-45 108-109 0-8-1-13-2-19z"/></symbol>',
  '<symbol id="icon-instagram" viewBox="0 0 26 26"><g fill="none" fill-rule="evenodd"><g fill="currentColor" fill-rule="nonzero"><path d="M18.13 1H7.53C3.928 1 1 3.955 1 7.587V18.28c0 3.632 2.929 6.587 6.53 6.587h10.6c3.601 0 6.53-2.955 6.53-6.587V7.587C24.66 3.955 21.731 1 18.13 1zm4.43 17.281c0 2.464-1.986 4.469-4.43 4.469H7.53c-2.443 0-4.43-2.005-4.43-4.469V7.587c0-2.465 1.987-4.47 4.43-4.47h10.6c2.443 0 4.43 2.005 4.43 4.47V18.28z"/><path d="M12.83 6.784c-3.362 0-6.097 2.759-6.097 6.15 0 3.391 2.735 6.15 6.097 6.15 3.362 0 6.097-2.759 6.097-6.15 0-3.391-2.735-6.15-6.097-6.15zm0 10.182c-2.204 0-3.997-1.809-3.997-4.032 0-2.224 1.793-4.032 3.997-4.032 2.204 0 3.997 1.808 3.997 4.032 0 2.223-1.793 4.032-3.997 4.032zM19.182 4.988c-.404 0-.801.166-1.087.455a1.567 1.567 0 00-.452 1.098 1.544 1.544 0 001.54 1.553c.405 0 .801-.166 1.088-.454a1.567 1.567 0 000-2.197 1.54 1.54 0 00-1.089-.455z"/></g></g></symbol>',
  '<symbol id="icon-twitter" viewBox="0 0 26 26"><g fill="none" fill-rule="evenodd"><path d="M25.028 5.279a.5.5 0 00-.514-.115l-.849.293c.5-.606.87-1.238 1.064-1.837a.474.474 0 00-.636-.582c-1.191.5-2.245.935-3.083 1.195a.117.117 0 01-.095-.013c-.668-.374-2.34-1.234-3.528-1.22-3.004.055-5.448 2.623-5.448 5.724v.17c-4.424-.857-6.868-2.13-9.515-4.907l-.413-.434-.275.533c-1.437 2.78-.419 5.271 1.26 6.95-.744-.115-1.284-.354-1.739-.751a.515.515 0 00-.834.522c.565 2 2.078 3.643 3.675 4.624-.771 0-1.406-.087-2.045-.497a.514.514 0 00-.766.605c.779 2.187 2.235 3.337 4.648 3.624-1.24.725-2.85 1.299-5.363 1.351a.585.585 0 00-.54.396.567.567 0 00.182.63c1.527 1.232 4.952 1.967 9.162 1.967 7.536 0 13.668-6.708 13.668-14.953V8.41c.975-.466 1.719-1.386 2.112-2.622a.497.497 0 00-.128-.508z" fill="currentColor" fill-rule="nonzero"/></g></symbol>',
  '<symbol id="icon-linkedin-boxed" viewBox="0 0 26 26"><g fill="none" fill-rule="evenodd"><path d="M23.27 1H2.73A1.73 1.73 0 001 2.73v20.54c0 .955.775 1.73 1.73 1.73h20.54A1.73 1.73 0 0025 23.27V2.73A1.73 1.73 0 0023.27 1zM9.512 19.14H6.59v-8.792h2.923v8.793zM8.052 9.148h-.02c-.98 0-1.614-.675-1.614-1.518 0-.863.653-1.52 1.653-1.52s1.615.657 1.634 1.52c0 .843-.634 1.518-1.653 1.518zm11.999 9.994h-2.922v-4.704c0-1.182-.423-1.989-1.48-1.989-.808 0-1.289.544-1.5 1.07-.077.187-.096.45-.096.712v4.91H11.13s.039-7.967 0-8.792h2.923v1.245c.388-.6 1.083-1.451 2.633-1.451 1.923 0 3.365 1.256 3.365 3.957v5.042z" fill="currentColor" fill-rule="nonzero"/></g></symbol>',
  '<symbol id="icon-facebook" viewBox="0 0 26 26"><g fill="none" fill-rule="evenodd"><path d="M10.701 25V14.053H7V9.786h3.701V6.64c0-3.651 2.241-5.64 5.514-5.64 1.567 0 2.914.117 3.307.168v3.816l-2.269.002c-1.78 0-2.125.841-2.125 2.076v2.724h4.246l-.553 4.267h-3.693V25H10.7z" fill="currentColor"/></g></symbol>',
  '<symbol id="icon-alert" viewBox="0 0 20 20"><path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-1-8h2V6H9v4zm0 4h2v-2H9v2z" /></symbol>',
  '<symbol id="icon-heart-24" viewBox="0 0 24 24"><path d="M11.69 20.182h.001a.465.465 0 00.618 0c.675-.597 1.23-1.078 2.285-1.988 2.85-2.458 3.898-3.428 4.881-4.628 1.256-1.533 1.825-2.947 1.825-4.486 0-1.492-.501-2.857-1.404-3.847A4.65 4.65 0 0016.413 3.7c-.997 0-1.903.316-2.711.949-.264.206-.657.621-1.161 1.235L12 6.542l-.54-.658c-.505-.614-.898-1.03-1.162-1.235-.808-.633-1.714-.949-2.71-.949a4.65 4.65 0 00-3.484 1.533C3.201 6.223 2.7 7.588 2.7 9.08c0 1.539.569 2.953 1.825 4.486.983 1.2 2.031 2.171 4.886 4.633l-.001-.002c1.068.92 1.609 1.39 2.28 1.985z" fill-rule="nonzero" stroke="inherit" stroke-width="1.4" fill="none"/></symbol>',
  '<symbol id="icon-heart-full-24" viewBox="0 0 24 24"><path d="M11.227 20.706a232.54 232.54 0 00-2.274-1.979l-.004-.003c-2.015-1.737-3.755-3.237-4.965-4.714C2.63 12.358 2 10.79 2 9.08c0-1.662.564-3.196 1.587-4.318a5.35 5.35 0 014-1.762c1.155 0 2.212.37 3.143 1.097.313.245.736.693 1.27 1.342.534-.65.957-1.097 1.27-1.342C14.202 3.37 15.26 3 16.414 3a5.35 5.35 0 014 1.762C21.438 5.884 22 7.418 22 9.08c0 1.711-.63 3.278-1.983 4.93-1.211 1.477-2.95 2.977-4.966 4.714-.688.593-1.468 1.266-2.278 1.982a1.166 1.166 0 01-1.546 0z" fill="url(#a)" fill-rule="evenodd"/></symbol>',
  '<symbol id="icon-3d-tour" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><g fill="currentColor" fill-rule="nonzero" stroke="#FFF" stroke-width=".1"><path d="M5.382 17.5c-.578 0-1.12-.061-1.623-.184-.503-.123-.923-.293-1.259-.51l.168-1.702a5.193 5.193 0 0 0 2.616.695c.644 0 1.145-.151 1.504-.454.36-.302.539-.718.539-1.247 0-.52-.173-.926-.518-1.22-.345-.292-.83-.439-1.455-.439-.252 0-.564.038-.937.114l-.49-1.134L6.641 8.47h-3.96V6.727h6.353v1.502L6.446 11.05c.596.048 1.114.201 1.553.461.438.26.776.61 1.014 1.049.238.44.357.952.357 1.538 0 1.049-.357 1.878-1.07 2.488-.714.61-1.687.914-2.918.914ZM11.594 6.5h4.072c1.847 0 3.28.47 4.302 1.41 1.021.94 1.532 2.261 1.532 3.962 0 1.71-.525 3.039-1.574 3.984-1.05.945-2.52 1.417-4.414 1.417h-3.918V6.5Zm2.127 9.03h1.749c2.546 0 3.82-1.215 3.82-3.643 0-2.43-1.27-3.643-3.806-3.643H13.72v7.286Z"/></g></g></symbol>',
  '<symbol id="icon-video" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" transform="translate(3.5 3.5)"><path stroke-linejoin="round" stroke-width="1.2" d="M11.5 8.875 7 11.5V6.25z"/><circle cx="8.5" cy="8.5" r="8.5" stroke-width="1.3"/></g></symbol>',
  '<symbol id="icon-view-list" viewBox="0 0 30 30"><g fill="none" fill-rule="evenodd"><g><circle fill="#F9F8FC" cx="15" cy="15" r="15"/><path d="M6 6h18v18H6z"/></g><path d="M21.103 6.39v17.096a.952.952 0 01-.952.952H10.055a.952.952 0 01-.952-.952V6.39c0-.526.426-.953.952-.953h10.096c.526 0 .952.427.952.953z" fill="#FFF"/><g fill="#343D42" fill-rule="nonzero"><path stroke="#979797" d="M12.716 18.628h4.511"/><g stroke="currentColor" stroke-width=".5"><path d="M20.637 7.455H18.12V6.91c0-.21-.17-.38-.381-.38h-.895A1.914 1.914 0 0014.971 5c-.923 0-1.695.658-1.873 1.53h-.894c-.21 0-.38.17-.38.38v.545H9.305c-.713 0-1.293.58-1.293 1.293v14.464c0 .713.58 1.293 1.293 1.293h11.331c.713 0 1.293-.58 1.293-1.293V8.748c0-.713-.58-1.293-1.293-1.293zm-8.052-.164h.856a.38.38 0 00.381-.38 1.15 1.15 0 012.299 0c0 .21.17.38.38.38h.857v1.85h-4.773V7.29zm8.583 15.921c0 .293-.238.53-.53.53H9.305a.532.532 0 01-.531-.53V8.748c0-.293.238-.531.53-.531h2.518V9.52c0 .21.17.381.381.381h5.535c.21 0 .38-.17.38-.38V8.216h2.518c.293 0 .531.238.531.53v14.465z"/><path d="M17.227 12.319h-4.511a.38.38 0 100 .762h4.51a.381.381 0 000-.762zM17.227 15.267h-4.511a.38.38 0 100 .762h4.51a.381.381 0 000-.762zM17.227 18.216h-4.511a.38.38 0 100 .762h4.51a.381.381 0 000-.762z"/></g></g></g></symbol>',
  '<symbol id="icon-view-map" viewBox="0 0 32 32"><g fill="none" fill-rule="evenodd"><circle cx="16" cy="16" r="16" fill="#F2F2F2" fill-rule="nonzero"/><g><mask id="d" fill="#fff"><use xlink:href="#c"/></mask><path d="m15.98 26.565 13.273 36.396a1.292 1.292 0 0 1-.772 1.657 1.295 1.295 0 0 1-1.66-.773L13.55 27.449a1.292 1.292 0 0 1 .773-1.657 1.295 1.295 0 0 1 1.659.773Zm4.303-35.719 5.79 15.876 6.191-2.249.885 2.427-6.192 2.248 7.484 20.525-2.43.883-7.485-20.524-4.94 1.794 7.53 20.647-2.432.883-7.529-20.647-22.906 8.322-.885-2.426 22.906-8.322L10.526-5.47l2.431-.883L18.701 9.4l4.94-1.794L17.85-8.27l2.432-.884Zm-9.018 31.961a1.289 1.289 0 0 1-.772 1.653L-.226 28.34a1.294 1.294 0 0 1-1.656-.775 1.289 1.289 0 0 1 .772-1.653L9.61 22.033a1.294 1.294 0 0 1 1.656.774Z" stroke="#B7C1D2" stroke-width=".5" fill="#FFF" fill-rule="nonzero" mask="url(#d)"/></g><g transform="translate(10 8)" fill-rule="nonzero"><path d="M12 6c0 3.314-4.333 10-6 10-1.667 0-6-6.686-6-10a6 6 0 1 1 12 0Z" fill="#FF0086"/><circle fill="#FFF" cx="6" cy="6" r="2.5"/></g></g></symbol>',
  '<symbol id="icon-remove" viewBox="0 0 18 18"><g fill="none" fill-rule="evenodd"><g><circle fill="transparent" cx="9" cy="9" r="9"/><rect fill="currentColor" transform="rotate(45 8.854 9.146)" x="7.854" y="4.146" width="2" height="10" rx=".9"/><rect fill="currentColor" transform="rotate(135 8.854 9.146)" x="7.854" y="4.146" width="2" height="10" rx=".9"/></g></g></symbol>',
  '<symbol id="icon-current-location" viewBox="0 0 24 24"><path d="M9.16 20.728L3.024 3.55a.41.41 0 01.525-.525l17.178 6.134a.41.41 0 01.045.755l-7.117 3.558a.415.415 0 00-.184.184l-3.558 7.117a.41.41 0 01-.755-.045z" stroke="currentColor" stroke-width="1.6" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></symbol>',
  '<symbol id="icon-current-location-right" viewBox="0 0 24 24"><path d="M5.454 9.87l12.884-4.601a.308.308 0 01.393.393l-4.6 12.884a.308.308 0 01-.566.034l-2.67-5.338a.312.312 0 00-.137-.138L5.42 10.435a.308.308 0 01.034-.565z" stroke="currentColor" stroke-width="1.35" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></symbol>',
  '<symbol id="icon-bed" viewBox="0 0 24 24"><g stroke="currentColor" stroke-width="1.5" fill="none" fill-rule="evenodd"><path d="M2.25 14.3h19.5m-19.5 3h19.5"/><path d="M5.838 4.5h12.324c.325 0 .588.263.588.588V9h0-13.5V5.088c0-.325.263-.588.588-.588zM5.25 9l-3 5.25v5.25m19.5 0v-5.25l-3-5.25" stroke-linecap="round" stroke-linejoin="round"/></g></symbol>',
  '<symbol id="icon-bath" viewBox="0 0 24 24"><g fill-rule="nonzero" fill="none"><path d="M21.75 11.19c0-.746-.718-.682-1.601-.682H4.937v-5.2c0-.598.487-1.085 1.086-1.085h.284c.295 0 .572.12.774.324l-.048.048a2.076 2.076 0 000 2.934l.274.275a.619.619 0 00.877 0l2.712-2.71a.62.62 0 000-.876l-.274-.275a2.064 2.064 0 00-1.469-.607c-.424 0-.828.126-1.17.36a2.33 2.33 0 00-1.676-.712h-.284a2.328 2.328 0 00-2.327 2.325v5.207c-.964-.104-1.446.12-1.446.674 0 .553.341.802 1.024.746v3.704a3.742 3.742 0 002.984 3.658l-.532.532a.62.62 0 10.877.876l1.334-1.331h8.126l1.334 1.331a.618.618 0 00.877 0 .62.62 0 000-.876l-.532-.532a3.742 3.742 0 002.984-3.658v-3.704c.683 0 1.024-.249 1.024-.746zM9.27 4.476c.17 0 .335.043.48.123L7.623 6.714a.986.986 0 01.168-1.177l.775-.772a.99.99 0 01.703-.29zM19.5 15.37c0 1.397-1.123 2.533-2.502 2.533H7.002c-1.38 0-2.502-1.136-2.502-2.533v-3.435h15v3.435z" stroke="currentColor" stroke-width=".2" fill="currentColor"/><path d="M9.677 7.782l-.251-.25a.25.25 0 00-.353 0 .247.247 0 000 .351l.251.25a.25.25 0 00.353-.35zm1.249-.249a.25.25 0 00-.353 0 .247.247 0 000 .35l.251.25a.25.25 0 00.353-.35l-.251-.25zm-.353-1.493a.247.247 0 000 .351l.251.25a.25.25 0 00.353-.35l-.251-.25a.25.25 0 00-.353 0z" fill="currentColor"/></g></symbol>',
  '<symbol id="icon-car" viewBox="0 0 24 24"><g transform="translate(1.8 5.628)" fill="none" fill-rule="evenodd"><path d="M16.977 10.24l2.014-.01c.384-.001.697.243.7.546l.023 3.156c.003.303-.307.55-.69.552l-2.015.01c-.384.001-.697-.243-.7-.546l-.023-3.156c-.003-.303.307-.55.69-.552zm-15.429 0l2.015-.01c.383-.001.697.243.699.546l.024 3.156c.002.303-.307.55-.691.552l-2.015.01c-.384.001-.697-.243-.699-.546l-.024-3.156c-.002-.303.307-.55.691-.552zm17.429-6.803c-.128.014-.225.147-.223.304.031.308-.13.596-.374.671l-.38.003.195.7s2.448-.018 2.375-1.006c-.057-.767-1.12-.728-1.593-.672zm-17.383 0c.128.014.225.147.224.304-.032.308.129.596.373.671l.38.003-.195.7S-.072 5.097.002 4.11c.056-.767 1.119-.728 1.592-.672z" fill="currentColor" fill-rule="nonzero"/><path d="M19.689 8.004a3.966 3.966 0 00-.484-1.615l-.985-1.793S16.47.684 15.74.373C15.037.072 10.54.004 10.241 0h-.016C9.927.008 5.43.129 4.73.438 4.005.758 2.3 4.69 2.3 4.69l-.96 1.804a3.97 3.97 0 00-.465 1.62 11.592 11.592 0 00.403 3.716c.157.57.682.964 1.278.958l7.746-.044h.017l7.746-.046a1.311 1.311 0 001.267-.973c.32-1.213.44-2.47.358-3.721z" stroke="currentColor" stroke-width="1.5" fill="#FFF" fill-rule="nonzero"/><path d="M16.953 8.504c-.013-.118-.113-.204-.224-.19a.19.19 0 00-.026.005l-1.74.453a.204.204 0 00-.125.098l-.345.608a.224.224 0 00.069.295c.032.02.068.031.105.031h1.7c.04 0 .078-.011.112-.034.4-.283.526-.724.474-1.266zm-13.62 0c.012-.118.113-.204.224-.19a.19.19 0 01.025.005l1.74.453a.204.204 0 01.126.098l.344.608a.224.224 0 01-.068.295.193.193 0 01-.105.031h-1.7a.195.195 0 01-.112-.034c-.401-.283-.527-.724-.474-1.266z" fill="currentColor" fill-rule="nonzero"/><rect fill="currentColor" x="7.714" y="8.526" width="5.143" height="1.5" rx=".75"/></g></symbol>',
  '<symbol id="icon-area" viewBox="0 0 24 24"><g transform="translate(4 4)" fill="none" fill-rule="evenodd"><path d="M8.205 8.327a.633.633 0 01.894 0l2.945 2.945-.003-1.605a.633.633 0 111.266.002v3.128a.633.633 0 01-.632.632H9.547a.633.633 0 11-.003-1.267l1.606.004-2.945-2.945a.633.633 0 010-.894zm-.457-.457a.633.633 0 01-.894 0L3.909 4.925l.003 1.606a.633.633 0 11-1.266-.003V3.4c0-.348.283-.632.632-.632h3.128a.633.633 0 11.003 1.267L4.803 4.03l2.945 2.945a.633.633 0 010 .894z" fill="currentColor"/><rect stroke="currentColor" stroke-width="1.5" width="16" height="16" rx="3.2"/></g></symbol>',
  '<symbol id="icon-phone" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M17.456 14.01c-.578-.572-1.3-.572-1.874 0-.439.434-.877.868-1.308 1.31-.118.122-.217.148-.36.067-.284-.155-.586-.28-.859-.45-1.27-.799-2.335-1.827-3.278-2.983-.468-.575-.884-1.19-1.175-1.882-.059-.14-.048-.232.066-.346.439-.424.866-.858 1.297-1.293.6-.604.6-1.311-.004-1.919-.342-.346-.685-.685-1.027-1.031-.354-.354-.704-.71-1.061-1.06-.578-.564-1.3-.564-1.875.003-.442.434-.865.88-1.315 1.307-.416.394-.626.877-.67 1.44-.07.917.155 1.783.471 2.626.649 1.746 1.636 3.297 2.833 4.718a17.527 17.527 0 005.804 4.542c1.017.493 2.07.872 3.216.935.788.044 1.473-.155 2.022-.77.375-.42.799-.803 1.197-1.204.589-.597.593-1.319.007-1.908-.7-.703-1.403-1.403-2.107-2.103z" stroke="currentColor" stroke-width="1.5"/></g></symbol>',
  '<symbol id="icon-email" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M3 7.8C3 6.253 4.15 5 5.571 5H18.43C19.849 5 21 6.253 21 7.8v8.4c0 1.547-1.15 2.8-2.571 2.8H5.57C4.151 19 3 17.747 3 16.2V7.8z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/><path d="M3.818 6.75l7.31 6.655c.505.46 1.239.46 1.745 0l7.309-6.655" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/></g></symbol>',
  '<symbol id="icon-calendar" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path fill="currentColor" d="M15.215 12.258a.81.81 0 0 1 .037.042l.06.074c.274.338.24.83-.079 1.126l-3.36 3.133-.017.01-.035.047a.81.81 0 0 1-1.18.103l-1.867-1.679a.827.827 0 0 1-.098-1.124l.06-.076a.81.81 0 0 1 1.138-.139l.042.036 1.243 1.119 2.912-2.713a.81.81 0 0 1 1.144.04Z"/><rect width="18.346" height="15.846" x="2.827" y="5.327" stroke="currentColor" stroke-width="1.654" rx="1.654"/><rect width="3.346" height="3.346" x="15.327" y="2.827" fill="#FFF" stroke="currentColor" stroke-width="1.654" rx=".827"/><path stroke="currentColor" stroke-linecap="square" stroke-width="1.654" d="M3.25 9.083h16.5"/><rect width="3.346" height="3.346" x="5.327" y="2.827" fill="#FFF" stroke="currentColor" stroke-width="1.654" rx=".827"/></g></symbol>',
  '<symbol id="icon-external-link" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M19.423 19.423v.56c0 .962-.78 1.742-1.742 1.742H3.742c-.962 0-1.742-.78-1.742-1.742V5.92c0-.962.78-1.742 1.742-1.742h1.525" stroke="currentColor" stroke-width="1.778"/><rect stroke="currentColor" stroke-width="1.778" x="5.916" y="2.889" width="15.645" height="15.645" rx="1.742"/><path d="M13.532 11.024a.613.613 0 01-.008-.865l2.823-2.824-1.553-.011a.626.626 0 01-.618-.617.602.602 0 01.609-.609l3.027.028c.337.004.614.28.617.618l.028 3.027a.602.602 0 01-.608.608.626.626 0 01-.617-.617l-.012-1.554-2.823 2.824a.613.613 0 01-.865-.008z" fill="currentColor"/></g></symbol>',
  '<symbol id="icon-expand" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><g fill="currentColor"><path d="M12.87 11.13a.743.743 0 010-1.05l3.456-3.455-1.884.004a.742.742 0 11.003-1.487h3.67c.41 0 .742.333.742.742v3.671a.742.742 0 11-1.486.003l.004-1.884-3.456 3.456a.743.743 0 01-1.049 0zM11.13 12.87c.29.29.288.76 0 1.05l-3.456 3.455 1.884-.004a.742.742 0 11-.003 1.487h-3.67a.742.742 0 01-.742-.742v-3.671a.742.742 0 111.486-.003l-.004 1.884 3.456-3.456a.743.743 0 011.049 0z"/></g></g></symbol>',
  '<symbol id="icon-contract" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><g fill="currentColor" fill-rule="nonzero"><path d="M18.64 5.36c.29.29.288.76 0 1.05l-3.456 3.455 1.884-.004a.742.742 0 11-.003 1.486h-3.67a.742.742 0 01-.742-.742v-3.67a.742.742 0 111.486-.003l-.004 1.884 3.456-3.456a.743.743 0 011.049 0zM5.36 18.64a.743.743 0 010-1.05l3.456-3.455-1.884.004a.742.742 0 11.003-1.486h3.67c.41 0 .742.332.742.742v3.67a.742.742 0 11-1.486.003l.004-1.884-3.456 3.456a.743.743 0 01-1.049 0z"/></g></g></symbol>',
  '<symbol id="icon-sms" viewBox="0 0 24 24" ><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12 19.008c5.523 0 10-3.36 10-7.504S17.523 4 12 4 2 7.36 2 11.504c0 2.804 2.05 5.25 5.086 6.537.7.297-.64 2.785.152 2.949.85.176 3.838-1.982 4.762-1.982z" stroke="#343D42" stroke-width="1.5"/></g></symbol>',
  '<symbol id="icon-like" viewBox="0 0 24 24" ><g fill="none" fill-rule="evenodd"><path d="M20.36 12.06c.067.238.1.487.1.747 0 .555-.14 1.074-.418 1.557.021.152.033.307.033.465 0 .729-.22 1.37-.66 1.926v.054c.007.988-.307 1.767-.942 2.336-.634.57-1.465.855-2.491.855h-1.233c-.77 0-1.503-.08-2.2-.238a25.55 25.55 0 01-2.454-.714c-.85-.288-1.357-.433-1.518-.433H5.408c-.388 0-.72-.135-.995-.405A1.323 1.323 0 014 17.23v-6.923c0-.382.137-.709.413-.979.275-.27.607-.406.995-.406h3.015c.264-.173.766-.732 1.507-1.676.433-.556.826-1.013 1.178-1.374.139-.152.253-.383.34-.693a6.57 6.57 0 00.193-.914c.04-.299.134-.625.28-.978.148-.354.346-.65.595-.888.286-.267.616-.4.99-.4.616 0 1.17.117 1.662.351.491.235.865.6 1.122 1.098.257.49.385 1.161.385 2.012 0 .671-.176 1.363-.528 2.077h1.936c.764 0 1.423.274 1.981.822.558.548.836 1.194.836 1.937 0 .642-.18 1.23-.54 1.763zm-1.297-2.72c-.286-.278-.613-.417-.98-.417H14.21c0-.418.176-.993.528-1.725.352-.732.529-1.311.529-1.737 0-.706-.118-1.23-.352-1.568-.235-.339-.705-.509-1.409-.509-.19.188-.33.495-.418.92-.088.426-.2.878-.336 1.358-.135.48-.353.874-.654 1.184a18.8 18.8 0 00-1.1 1.309c-.14.18-.255.328-.347.443a21.27 21.27 0 01-.38.46c-.16.191-.307.35-.44.476-.132.126-.273.254-.423.384a1.86 1.86 0 01-.44.292.957.957 0 01-.39.098h-.353v6.923h.352c.096 0 .211.01.347.032a5 5 0 01.363.07c.106.026.245.065.418.12a16.918 16.918 0 01.776.26c.176.06.282.098.319.113 1.547.526 2.802.79 3.763.79h1.408c.638 0 1.137-.149 1.496-.444.36-.296.54-.75.54-1.363 0-.188-.02-.39-.055-.606.22-.115.394-.305.522-.568.129-.263.193-.528.193-.795s-.066-.515-.198-.746c.388-.36.582-.79.582-1.287 0-.18-.036-.38-.11-.6a1.447 1.447 0 00-.274-.515c.234-.007.43-.176.588-.508.158-.332.237-.624.237-.876 0-.368-.143-.69-.43-.969z" fill="currentColor"/><path d="M0 0h24v24H0z"/></g></symbol>',
  '<symbol id="icon-comment" viewBox="0 0 24 24" ><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12 19.008c5.523 0 10-3.36 10-7.504S17.523 4 12 4 2 7.36 2 11.504c0 2.804 2.05 5.25 5.086 6.537.7.297-.64 2.785.152 2.949.85.176 3.838-1.982 4.762-1.982z" stroke="currentColor" stroke-width="1.5"/></g></symbol>',
  '<symbol id="icon-filter" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><path d="M0 0h20v20H0z"/><g fill="currentColor"><g transform="translate(2.5 8.2)"><circle cx="11.443" cy="1.8" r="1.8"/><rect y="1" width="15" height="1.6" rx=".8"/></g><g transform="matrix(-1 0 0 1 17.5 3.2)"><circle cx="11.443" cy="1.8" r="1.8"/><rect y="1" width="15" height="1.6" rx=".8"/></g><g transform="matrix(-1 0 0 1 17.5 13.2)"><circle cx="11.443" cy="1.8" r="1.8"/><rect y="1" width="15" height="1.6" rx=".8"/></g></g></g></symbol>',
  '<symbol id="icon-sort" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><path d="M0 0h20v20H0z"/><g fill="currentColor" fill-rule="nonzero" stroke="currentColor" stroke-width=".5"><path d="M2.673 7.643 5.99 4.552c.002-.003.018-.003.02 0l3.317 3.09a.4.4 0 1 0 .546-.585l-3.318-3.09a.821.821 0 0 0-1.11 0l-3.318 3.09a.4.4 0 1 0 .546.586ZM10.673 12.357l3.317 3.091c.002.003.018.003.02 0l3.317-3.09a.4.4 0 1 1 .546.585l-3.318 3.09a.821.821 0 0 1-1.11 0l-3.318-3.09a.4.4 0 1 1 .546-.586Z"/><path d="M13.5 15.25v-11a.5.5 0 0 1 1 0v11a.5.5 0 0 1-1 0ZM5.5 4.75v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0Z"/></g></g></symbol>',
  '<symbol id="icon-info" viewBox="0 0 20 20"><g transform="translate(1 1)" fill="none" fill-rule="evenodd"><path d="M7.905 12.444c.276 0 .485-.086.63-.258.143-.172.215-.42.215-.744V7.346c0-.324-.076-.572-.228-.744-.152-.172-.365-.258-.64-.258-.264 0-.468.086-.612.258-.143.172-.215.42-.215.744v4.096c0 .328.073.577.218.747.146.17.357.255.632.255ZM7.9 5.122c.288 0 .52-.08.696-.243a.826.826 0 0 0 .264-.638.836.836 0 0 0-.264-.64c-.176-.165-.406-.247-.69-.247-.287 0-.52.082-.695.246a.836.836 0 0 0-.265.641.83.83 0 0 0 .262.638c.174.162.405.243.692.243Z" fill="currentColor" fill-rule="nonzero"/><circle stroke="currentColor" stroke-width="1.244" cx="8" cy="8" r="8"/></g>',
  '<symbol id="icon-checkbox" viewBox="0 0 20 20"><rect x="40" y="159" width="18" height="18" rx="2" transform="translate(-39 -158)" stroke="currentColor" fill="none" fill-rule="evenodd"/></symbol>',
  '<symbol id="icon-checkbox-on" viewBox="0 0 20 20"><g transform="translate(1 1)" fill="none" fill-rule="evenodd"><rect stroke="currentColor" fill="currentColor" width="18" height="18" rx="2"/><path stroke="#FFF" stroke-width=".96" fill="#FFF" fill-rule="nonzero" d="M8.073 10.966 5.009 7.877 4 8.895 8.073 13 15 6.017 13.991 5z"/></g></symbol>',
  '<symbol id="icon-radio" viewBox="0 0 20 20"><rect x="539" y="263" width="18" height="18" rx="9" transform="translate(-538 -262)" stroke="currentColor" fill="none" fill-rule="evenodd"/></symbol>',
  '<symbol id="icon-radio-on" viewBox="0 0 20 20"><g transform="translate(1 1)" fill="none" fill-rule="evenodd"><rect fill="currentColor" x="3" y="3" width="12" height="12" rx="6"/><rect stroke="currentColor" width="18" height="18" rx="9"/></g></symbol>',
  '</svg>',
].join('');

export default icons;
