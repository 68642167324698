import type { PropsWithChildren } from 'react'
//  import { ApolloProvider } from '@apollo/client'

import { AuthProvider } from './auth-context'
import { UserProvider } from './user-context'
import { AlertsProvider } from './alerts-context'
import { FeaturesProvider } from './features-context'
import { PageInfoProvider } from './pageInfo-context'
import { LocationsProvider } from './locations-context'
import { CollectionsProvider } from './collections-context'
// import { client } from '~/graphql/client'

export const Providers = (props: PropsWithChildren) => {
  return (
    <PageInfoProvider>
      {/* <ApolloProvider client={client}> */}
      <LocationsProvider>
        <AuthProvider>
          <UserProvider>
            <CollectionsProvider>
              <AlertsProvider>
                <FeaturesProvider>{props.children}</FeaturesProvider>
              </AlertsProvider>
            </CollectionsProvider>
          </UserProvider>
        </AuthProvider>
      </LocationsProvider>
      {/* </ApolloProvider> */}
    </PageInfoProvider>
  )
}
