import { captureRemixErrorBoundaryError } from '@sentry/remix'
import {
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from '@remix-run/react'
import IconsSprite from './components/ui/icon-sprites'
import { Providers } from '~/lib/providers'

import { globals } from '~/styles/global'
import { getEnv } from './lib/env.server'
import { LoaderFunction } from '@remix-run/cloudflare'

export const loader: LoaderFunction = () => {
  return { ENV: getEnv() }
}


export function Layout({ children }: { children: React.ReactNode }) {
  const { ENV } = useLoaderData<typeof loader>()
  const showThirdPartyScripts = false
  const googleTagManagerKey = ''
  const icons = ''

  return (
    <html lang="en">
      <head>
        <link rel="preconnect" href="https://gtm.homely.com.au" crossOrigin="" />
        <link rel="preconnect" href="https://az416426.vo.msecnd.net" crossOrigin="" />
        <link rel="preconnect" href="https://pagead2.googlesyndication.com" crossOrigin="" />
        <link rel="preconnect" href="https://img-4.homely.com.au" crossOrigin="" />
        <link rel="preconnect" href="https://connect.facebook.net" crossOrigin="" />
        <link rel="preconnect" href="https://www.googleadservices.com" crossOrigin="" />
        <link rel="preconnect" href="https://accounts.google.com" crossOrigin="" />
        <link
          rel="preload"
          href="/assets/fonts/objektiv-regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/assets/fonts/objektiv-medium.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/assets/fonts/objektiv-bold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className={globals}>
        {showThirdPartyScripts && (
          <noscript
            dangerouslySetInnerHTML={{
              __html: `<iframe src="https://gtm.homely.com.au/ns.html?id=GTM-KB9WGMW&${googleTagManagerKey}&gtm_cookies_win=x" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
            }}
          />
        )}
        <span
          dangerouslySetInnerHTML={{
            __html: icons,
          }}
        />
        <IconsSprite />
        <Providers>{children}</Providers>
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`,
          }}
        />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export const ErrorBoundary = () => {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)
  return <div>Something went wrong</div>
}

export default function App() {
  return <Outlet />
}
