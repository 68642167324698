import { css } from "@linaria/core"
import theme from "./theme"
import legacy from "./legacy"
import normalize from "./normalize"

export const globals = css`
  ${normalize}

  :global() {
    *,
    :after,
    :before {
      box-sizing: border-box;
    }

          @font-face {
            font-family: 'objektiv';
            font-weight: 400;
            font-style: normal;
            font-display: swap;
            src:
              url('/assets/fonts/objektiv-regular.woff2') format('woff2'),
              url('/assets/fonts/objektiv-regular.woff') format('woff');
          }
          @font-face {
            font-family: 'objektiv';
            font-weight: 500;
            font-style: normal;
            font-display: swap;
            src:
              url('/assets/fonts/objektiv-medium.woff2') format('woff2'),
              url('/assets/fonts/objektiv-medium.woff') format('woff');
          }
          @font-face {
            font-family: 'objektiv';
            font-weight: 700;
            font-style: normal;
            font-display: swap;
            src:
              url('/assets/fonts/objektiv-bold.woff2') format('woff2'),
              url('/assets/fonts/objektiv-bold.woff') format('woff');
          }

    html {
      -webkit-font-smoothing: antialiased;
      min-height: calc(100vh + env(safe-area-inset-top));
      padding: 0;
    }

    body {
      margin: 0;
      width: 100%;
      min-height: -webkit-fill-available;
      font-family: 'objektiv';
      font-weight: ${theme.fontWeights.regular};
      color: ${theme.colors.grayBase};
      font-size: 16px;
      line-height: 32px;
    }

    /* Legacy */

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      font-weight: ${theme.fontWeights.regular};
      font-kerning: normal;
    }

    p,
    ul,
    ol,
    dl,
    pre,
    table,
    blockquote {
      margin: 0 0 16px;
      padding: 0;
    }

    b,
    strong {
      font-weight: ${theme.fontWeights.bold};
    }

    img {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      height: auto;
    }

    svg:not(:root) {
      overflow: hidden;
    }

    a {
      text-decoration: none;
    }

    a:active,
    a:hover {
      cursor: pointer;
      outline: 0;
    }

    a,
    button {
      cursor: pointer;
      outline: 0;

      body.keynav-mode &:focus {
        box-shadow: 0 0 0 2px ${theme.colors.inputFocus};
      }
    }
  }

  ${legacy}
`
